import React, { useState, useRef, useEffect } from "react";
import classNames from "classnames";

// Components
import Select from "../../../components/Select";
import Input from "../../../components/Input";
import Filter from "../../../components/Filter";
import Close from "../../../components/Close";
import ModalInput from "../../../components/admin-modals/ModalInput";
import ModalInfoAction from "../../../components/admin-modals/ModalInfoAction";
let timeInterval = 5000;
let startTime = 0;
var timer = null;

const ProcedureUsersTools = (props) => {

  const [resetFilter, setResetFilter] = useState(0);
  const [filterStatus, setFilterStatus] = useState("");
  const [emailSearch, setEmailSearch] = useState("");
  const [ids, setIds] = useState(props.ids);
  const [allIds, setAllIds] = useState([]);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showModal, setShowModal] = useState({});
  const [filterInvitedBy, setFilterInvitedBy] = useState({});
  
  useEffect(() => {
    if(Object.keys(filterInvitedBy).length > 0 && filterInvitedBy.value){
      props.setSearchInvitedBy(filterInvitedBy.value)  
    }
  }, [filterInvitedBy])

  useEffect(() => {
    clearTimeout(timer); 
    timer = setTimeout(() => {props.setSearchValue(emailSearch)}, 1000) 
  }, [emailSearch]);

  useEffect(() => {
    props.setStatusValue(filterStatus)
  }, [filterStatus])

  const optionEmails = () => {
    return props.data.emails.map((email, index) => {
      return { value: email.id, text: email.name };
    });
  };

  let tableFilterClasses = classNames(
    "c-table-filter",
    props.tableFilterClassName
  );

  let OPTIONS = [
    { text: "Actions", value: 0, action: () => {} },
    {
      text: "Send email",
      value: 1,
      action: () => {
        props.ids.length > 0
          ? setShowInvitationModal(true)
          : {};
      },
    },
  ];

  if (props.canManage === undefined ? true : props.canManage) {
    OPTIONS.push({
      text: "Remove",
      value: 2,
      action: () => {
        checkStatus()
          ? openModal({
              text: "Users will be removed",
              action: () => props.deleteUsers(getOnlyIds(), () => {setShowModal({}), setIds([]), setAllIds([]) }),
            })
          : openModal({
              text: "You can only remove users with status: Pending",
              action: () => setShowModal({}),
              type: "error",
            });
      },
    });
  };

  const getOnlyIds = () => {
    return props.ids.map((item) =>
      item.id
    );
  };
  const openModal = (data) => {
    if (props.ids.length > 0) {
      setShowModal(data);
    }
  };

  const checkStatus = () => {
    return ids.every((item) => item.status === "pending");
  };

  const actions = (value) => {
    OPTIONS[value].action();
  };

  const userFiltered = () => {
    let users = [];
    users = props.data.users.users;
    return users;
  };

  const addFilter = (value) => {
    userFiltered();
    setFilterStatus(value);
    // props.updateChart(value);
  };

  const deleteFilter = () => {
    setResetFilter(resetFilter + 1);
    setFilterStatus("");
    setFilterInvitedBy({})
    props.setStatusValue("")
    props.setSearchInvitedBy("")    
    // props.updateChart("");
  };

  useEffect(() => {
    let ids =
      props.data && props.data.users.users.map((item) => item.id);
    setAllIds(ids);

    return () => {
      setAllIds([])
    };
  }, [props.data]);

  return (
    <div className={tableFilterClasses}>
      <div className="c-table-filter__filters">
        <div className="c-table-filter__filters-item">
          <p className="c-table-filter__filters-label">Actions:</p>
          <Select
            className="c-select--white-bg c-select--border"
            errorLabel=""
            withoutInput={true}
            defaultValue={""}
            options={OPTIONS}
            optionClick={(text, value) => actions(value)}
          />
        </div>
        {props.filters.length > 0 && (
          <div className="c-table-filter__filters-item">
            <p className="c-table-filter__filters-label">Filter:</p>
            <Select
              key={`${resetFilter}-filter`}
              className="c-select--white-bg c-select--border"
              errorLabel=""
              withoutInput={true}
              defaultValue={""}
              options={props.filters}
              optionClick={(text, value) => {setFilterStatus(value)}}
            />
          </div>
        )}
        <div className="c-table-filter__filters-item">
          <form>
            <Input
              className="c-input--white-bg c-input--border-color"
              wrapperClassName="c-input__wrapper--no-size"
              name="search"
              label="Search emails, names and surnames:"
              search={true}
              errorLabel="Este campo no puede estar vacío"
              minCharacters={5}
              type="text"
              value={emailSearch}
              onChange={(e) => {
                setEmailSearch(e.currentTarget.value);
                

              }}
            />
          </form>
        </div>
        {props.invited_by_select.length > 0 && (
          <div className="c-table-filter__filters-item">
            <p className="c-table-filter__filters-label">Invited By:</p>
            <Select
              key={`${resetFilter}-filter`}
              className="c-select--white-bg c-select--border"
              errorLabel=""
              withoutInput={true}
              defaultValue={""}
              options={props.invited_by_select}
              optionClick={(text, value) => {setFilterInvitedBy({text: text, value: value})}}
            />
          </div>
        )}
      </div>
      {(filterStatus !== "" || Object.keys(filterInvitedBy).length > 0) && (
        <React.Fragment>
          <div className="c-filter-box">
            <div className="c-filter-box__wrapper c-filter-box__wrapper--min-width c-filter-box__wrapper--align-start">
              <div className="c-filter-box__wrapper c-filter-box__wrapper--align-center">
                <Filter width="40px" height="40px" />
                <p className="c-filter-box__text">Selected filters:</p>
              </div>
            </div>
            <div className="c-filter-box__wrapper">
              <div
                className="c-filter-box__box"
                onClick={() => deleteFilter()}
              >
                <p className="c-filter-box__box-text">{filterStatus}, {Object.keys(filterInvitedBy).length > 0 ? filterInvitedBy.text : ''}</p>
                <Close width="10px" height="10px" />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      {
        props.data &&
        <div className="o-margin--bottom-size-l">
          <p>
            Total users: <span className="o-font--semibold">{props.data.users.total_counter}</span>
          </p>
        </div>
      }
      
      {showInvitationModal && (
        <ModalInput
          title={"Send email"}
          label={"Select the email you want to send"}
          options={optionEmails()}
          showModal={true}
          closeModal={() => {
            setShowInvitationModal(false), setIds([]);
          }}
          action={(option) => props.sendEmail(option, () => {
              setShowInvitationModal(false); 
              setIds([]); 
              setShowModal({
                text: "Sent successfully",
                action: () => setShowModal({}),
                type: "error",
                })
              })
            }
          type={"select"}
          canManage={true}
        />
      )}
      {Object.keys(showModal).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          closeModalInfoAction={() => {
            setShowModal({});
            setIds([]);
          }}
          noButtonCancel={["error", "success"].includes(showModal.type)}
          text={showModal.text}
          action={() => showModal.action()}
        />
      )}   

    </div>
  )
};

export default ProcedureUsersTools;
