import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

// Components
import Aside from "../../components/Aside";
import BubbleBg from "../../components/BubbleBg";
import Export from "../../components/Export";
import Download from "../../components/Download";
import Input from "../../components/Input";
import Roles from "../../config/roles";
import ProcedureInfo from "./components/ProcedureInfo";
import Spinner from "../../components/Spinner";
import IllustrationLineSeparator from "../../components/IllustrationLineSeparator";
import ModalCreate from "../../components/admin-modals/ModalCreate";
import TableFilter from "../../components/TableFilter";
import ProcedureUsersTools from "./components/ProcedureUsersTools";
import ProcedureTableReport from "./components/ProcedureTableReport";
import ModalInvitationFolder from "../../components/admin-modals/ModalInvitationFolder";
import ModalInput from "../../components/admin-modals/ModalInput";
import ModalInfoAction from "../../components/admin-modals/ModalInfoAction";
import ModalSelectOrganization from "../../components/admin-modals/ModalSelectOrganization";
import Envelope from "../../components/Envelope";
import EditConsent from "../../components/EditConsent";
import AddUser from "../../components/AddUser";

// Actions
import {
  fetchProcedure,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchOrganizations,
  fetchProcedureUpdate,
  fetchProcedureAddUsers,
  fetchProcedureRemoveUsers,
  fetchEmailsProcedureSend,
  fetchKillerExportAll,
  fetchKillerFilesAll,
  fetchSwitchConfigFolder
} from "../admin/actions";

import { fetchLogout } from "../login/actions";

// Selectors
import { getOrganization, getOrganizations } from "../organizations/selector";

const ProceduresShow = (props) => {
  const [modals, setModals] = useState({});
  const [loading, setLoading] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [ids, setIds] = useState([]);
  const [allIds, setAllIds] = useState([]);
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showDownloadAllFilesModal, setShowDownloadAllFilesModal] = useState(false);
  const [showModal, setShowModal] = useState({});
  const [filterInvitedBy, setFilterInvitedBy] = useState("");  

  const [search, setSearch] = useState("")
  const [filterStatus, setFilterStatus] = useState("")
  const [selectAll, setSelectAll] = useState("asc");

  useEffect(() => {
    fetchProcedure()
    props.organizations.length === 0 && props.fetchOrganizations();
    props.organization === undefined &&
      props.fetchOrganization(props.match.params.organizationId, 1, 10);
    //getDataChart();
  }, []);

  useEffect(() => {
      if(props.procedure){
        setLoading(true);
        props.fetchProcedure(
        props.match.params.organizationId,
        props.match.params.id,
        1,
        20,
        search,
        filterStatus,
        "",
        "",
        filterInvitedBy,
        () => setLoading(false)
        )  
      }
      
    },  [search, filterStatus, filterInvitedBy])
  const fetchProcedure = () => {
    props.fetchProcedure(
      props.match.params.organizationId,
      props.match.params.id,
      1,
      20,
      "",
      "",
      "",
      "",
      "",
      () => {
        setLoading(false);
        setFilteredUsers([]);
        setIds([]);
        setAllIds([])
      }
    );
  }
  const submitEditProcedure = (data) => {
    let organizationId = props.match.params.organizationId
    let procedureId = props.match.params.id

    props.fetchProcedureUpdate(organizationId, procedureId, data, () => {
      setModals({
        type: "success",
        text: "The procedure has been edited successfully!",
        action: () => setModals({}),
      });
      fetchProcedure()
      getDataChart();
    }, () => alert("Error"));
  };

  const actionModals = (type) => {

    let modal = {};
    if (type === "createOrganization") {
      modal.type = type;
      modal.text = "New organization:";
      modal.action = (data) => submitCreateOrganization(data);
    } else if (type === "edit_procedure") {
      modal.type = type;
      modal.text = "Edit procedure";
      modal.action = (data) => submitEditProcedure(data);
    } else if (type === "invite") {
      modal.type = type;
      modal.text = "Invite users";
      modal.action = (data) => alert("entra");      
    } else {
      modal.type = type;
      modal.action = () => { };
    }

    setModals(modal);
  };

  const changeOrganization = (id) => {
    props.history.push(`/admin/organizations/${id}`);
  };

  const getAssessments = () => {
    props.fetchOrganizationGetAssessments(
      props.match.params.organizationId,
      () => {
        let modal = {};
        modal.type = "copy_folder";
        modal.text = "Copy Folder";
        modal.description = "This will copy all user to the new folder. It could take a while depending on the number of users."
        modal.action = (data) => { };
        setModals(modal);
      }
    );
  };

  const submitCreateOrganization = (data) => {
    props.fetchOrganizationCreate({ organization: data, from_organization: props.match.params.organizationId }, () => {
      setModals({
        type: "success",
        text: "The organization has been created successfully!",
        action: () => setModals({}),
      }),
        props.fetchOrganizations();
    });
  };

  // Logout
  const closeSession = () => {
    removeAdminScopeClass();
    props.fetchLogout() && props.history.push("/signin");
  };

  // Remove s-admin Class
  const removeAdminScopeClass = () => {
    let sAdmin = document.querySelector(".s-admin");
    sAdmin && sAdmin.classList.remove("s-admin");
  };

  const getDataChart = (status = filterStatus) => {
    // props.fetchFolderUpdateChart(
    //   props.match.params.organizationId,
    //   props.match.params.folderId,
    //   status,
    //   () => {},
    //   () => {}
    // );
  };

  //TOOLS
  const openModal = (data) => {
    // if (ids.length > 0) {
    //   setShowModal(data);
    // }
  };

  const getIds = (ids, select_all = false) => {
    setSelectAll(select_all)
    setIds(ids);
  };

  const submitInvitation = (data, file) => {
    props.fetchProcedureAddUsers(
      props.match.params.organizationId,
      props.match.params.id,
      {emails: data, file: file},
      () => {
        fetchProcedure()
        setModals("")
      },
      () => alert("ko")
    );
  }
  
  const deleteUsers = (data, closeModal) => {
    props.fetchProcedureRemoveUsers(
      props.match.params.organizationId,
      props.match.params.id,
      data,
      () => {
        fetchProcedure()
        closeModal()
      },
      () => alert("ko")
    );    
  }
  const invitedUserFromIcon = (item) => {
    setIds([item]);
    // setIds([{ procedureUserIds: item.id }]);
    // setShowModal({
    //   text: "Se va a enviar la invitación al usuario",
    //   action: () => invitedUser([item.user_folder_id]),
    // });
    setShowInvitationModal(true);
  }; 

  const getOnlyIds = () => {
    return ids.map((item) => item.id);
  };
  const sendEmail = (idEmail, success_sent) => {
    props.fetchEmailsProcedureSend(
      props.match.params.organizationId,
      props.procedure.id,
      idEmail,
      { custom_mail: { procedure_user_ids: getOnlyIds(), select_all: selectAll, search: search, status: filterStatus } },
      (data) => {
        success_sent();
        setShowInvitationModal(false);
        setShowModal({
          text: data,
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "success",
        });

      },
      (data) => {
        setShowInvitationModal(false);
        setShowModal({
          text: data,
          action: () => {
            setShowModal(false), setIds([]);
          },
          type: "error",
        });
      }
    );
  };

  const deleteUserFromIcon = (item) => {
      setShowModal({
        text: "Do you want to remove this user?",
        action: () => deleteUsers([item.id], () => setShowModal({})),
      });
    // if (item.status === "pending") {
    //   setIds([item.user_folder_id]);
    //   setShowModal({
    //     text: "Do you want to remove this user?",
    //     action: () => deleteUser([item.user_folder_id]),
    //   });
    // } else {
    //   setShowModal({
    //     text: "You can only remove users with status: Pending",
    //     action: () => {
    //       setShowModal({});
    //       setIds([]);
    //     },
    //     type: "error",
    //   });
    // }
  };
  const optionEmails = () => {
    return props.procedure.emails.map((email, index) => {
      return { value: email.id, text: email.name };
    });
  };

  const exportAllAnswersFiles = (data, id) => {
    props.fetchKillerFilesAll(props.match.params.organizationId, props.procedure.id, data["email"], () => {
      setShowDownloadAllFilesModal(false);
      setModals({
        type: "success",
        text: "The process has been successfully!",
        action: () => setModals({}),
      });
    }, () => alert("ko"));
  }

 const switchConfig = (key, folder_id) => {  
    props.fetchSwitchConfigFolder(
      props.match.params.organizationId,
      folder_id,
      {key: key},
      (repsonseJSON) => {
        setModals({
          type: "success",
          text: "Configuration successfully updated!",
          action: () => {
            fetchProcedure()
            setLoading(true);            
          },
        });
      }
    )
 }
 console.log("props.procedure", props.procedure)
  return (
    <motion.section
      className="o-wrapper"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <BubbleBg />

      {props.organizations &&
        props.organizations.length > 0 &&
        props.organization &&
        props.organization.data && (
          <Aside
            history={props.history}
            label={"Select organization:"}
            showSelect={true}
            organizations={props.organizations}
            organization={props.organization}
            selectAction={(id) => changeOrganization(id)}
            createOrganization={() => getAssessments()}
            selectOrganization={() => setModals("selectOrganization")}
            showModalSession={() =>
              setModals({
                type: "closeSession",
                text: "Are you sure you want to logout?",
                action: () => closeSession(),
              })
            }
          />
        )}

      <div className="o-container o-container--overflow-disabled">
        <ProcedureInfo
          totalUsers={props.procedure && props.procedure.users.users.length}
          data={props.procedure && props.procedure}
          chart={[]}
          switchConfig={(key, folder_id) => switchConfig(key, folder_id)}
          editProcedure={() => actionModals("edit_procedure")}
          canManage={
                props.organization &&
                props.organization.user_role !== Roles.admin.READER
              }
          organization_id={props.match.params.organizationId}
          // editProcedure={() => actionModals("edit")}
        />

        <IllustrationLineSeparator />
        <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center">
          <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
            <button
                type="button"
                className={"c-button c-button--margin-zero"}
                onClick={() => {
                  props.history.push(
                    `/admin/organizations/${props.organization.data.id}/procedures/${props.procedure.id}/emails`
                  )
                }}
              >
              <div className="c-button__icon">
                <Envelope width="20px" height="30px"/>
              </div>
              <span className="c-button__text">Emails</span>
            </button>
            <button
                type="button"
                className={"c-button c-button--margin-zero"}
                onClick={() => {
                  props.history.push(
                    `/admin/organizations/${props.organization.data.id}/procedures/${props.procedure.id}/consent`
                  )
                }}
              >
              <div className="c-button__icon">
                <EditConsent width="24px" height="34px"/>
              </div>
              <span className="c-button__text">Consent</span>
            </button>
          </div>
        </div>
        <div className="o-margin--top-size-xl o-display--flex o-display--flex-v-center">
          <h4 className="o-margin--right-size-l">Users list</h4>
           <div className="c-button__wrapper c-button__wrapper-width-auto c-button__wrapper-has-separation">
          {  props.organization &&
                props.organization.user_role !== Roles.admin.READER && props.procedure && !props.procedure.public && 
            <button
              type="button"
              className={"c-button c-button--margin-zero"}
              onClick={() => {
                actionModals("invite");
              }}
            >

              <div className="c-button__icon">
                <AddUser width="24px" height="34px"/>
              </div>
            <span className="c-button__text">Add users</span>
          </button> }
          {
            props.procedure && props.procedure.killer_questions && Object.keys(props.procedure.killer_questions).length > 0 &&
            <React.Fragment>
              <button
                type="button"
                className="c-button c-button--margin-zero"
                onClick={() => {
                  props.fetchKillerExportAll(props.match.params.organizationId, props.procedure.id, () => alert("ok"), () => alert("ko"));
                }}
              >
                <div className="c-button__icon">
                  <Export width="20px" height="30px" />
                </div>
                <span className="c-button__text">Export all answers</span>
              </button>
              <button
                type="button"
                className="c-button c-button--margin-zero"
                onClick={() => {
                  setShowDownloadAllFilesModal(true)
                }}
              >
                <div className="c-button__icon">
                  <Download width="20px" height="30px" />
                </div>
                <span className="c-button__text">Downlaod all answers files</span>
              </button>  
            </React.Fragment>
          }
          
          </div>
        </div>
          <ProcedureUsersTools
            data={props.procedure}
            filters={[{text: 'Pending', value: 'pending'}, {text: 'In progress', value: 'in_progress'}, {text: 'Completed', value: 'completed'}]}
            updateChart={(value) => getDataChart(value)}
            setSearchValue={(search) => setSearch(search)}
            setStatusValue={(status) => setFilterStatus(status)}
            emailSearch={search}
            setSearchInvitedBy={(invited_id) => setFilterInvitedBy(invited_id)}
            invited_by_select={props.procedure ? props.procedure.invited_by_select : []}
            titleTableFilter="Users list"
            tableFilterClassName={"c-table-filter--four-columns"}
            openModal={(data) => openModal(data)}
            canManage={
              props.organization &&
              props.organization.user_role !== Roles.admin.READER
            }
            ids={ids}
            filteredUsers={(data) => setFilteredUsers(data)}
            deleteUsers={(ids, closeModal) => deleteUsers(ids, closeModal)}
            sendEmail={(option, success_sent) => sendEmail(option, () => success_sent())}
            //openExportModal={() => props.openExportModal()}
          />
        {props.procedure && props.procedure.users.users.length > 0 ? (
          <React.Fragment>
            <ProcedureTableReport 
              toPage={(page, type = "", mode = "") =>{
                setLoading(true);
                props.fetchProcedure(
                    props.match.params.organizationId,
                    props.match.params.id,
                    page,
                    20,
                    search,
                    filterStatus,
                    type,
                    mode,
                    filterInvitedBy,
                    () => setLoading(false)
                  )
                } 

               }
              search={search}
              filterStatus={filterStatus}
              current_page={props.procedure.users.current_page}
              total_pages={props.procedure.users.total_pages}
              data={props.procedure.users.users}
              ids={ids}
              allIds={allIds}
              getIds={(ids, select_all) => getIds(ids, select_all)}
              canManage={
                props.organization &&
                props.organization.user_role !== Roles.admin.READER
              }
              goTo={(id) =>
                props.history.push(
                  `/admin/organizations/${props.organization.data.id}/procedures/${props.procedure.id}/procedure_users/${id}`
                )
              }
              openInvitedModal={(item) => invitedUserFromIcon(item)}  
              openDeleteModal={(item) => deleteUserFromIcon(item)}        
            />
          </React.Fragment>
        ) : (
          <>
            <h2 className="o-margin--top-size-xxl o-margin--bottom-size-xxl o-text-align--center">
              No users yet
            </h2>
            <IllustrationLineSeparator />
          </>
        )}
      </div>
      {["success"].includes(modals.type) && (
        <ModalInfoAction
          showModalInfoAction={true}
          noButtonCancel={["success"].includes(modals.type)}
          closeModalInfoAction={() => setModals("")}
          text={modals.text}
          primaryButtonText={"Ok"}
          action={() => modals.action ? (modals.action(),setModals("")) : setModals("")}
        />
      )}
      {["createOrganization", "edit_procedure"].includes(modals.type) && (
        <ModalCreate
          showModal={true}
          closeModal={() => setModals("")}
          editAction={(data) => modals.action(data)}
          defaultData={props.procedure}
          type={modals.type}
          title={modals.text}
          biodataOptions={[]}
        />
      )}
      {["invite"].includes(modals.type) && (
        <ModalInvitationFolder
          showModalInvitationFolder={true}
          closeInvitationFolder={() => setModals("")}
          invitationAction={(data, file) => submitInvitation(data, file)}
        />
      )}
      {showInvitationModal && (
        <ModalInput
          title={"Send email"}
          label={"Select the email you want to send"}
          options={optionEmails()}
          showModal={true}
          closeModal={() => {
            setShowInvitationModal(false), setIds([]);
          }}
          action={(option) => sendEmail(option, () => "")}
          type={"select"}
          canManage={true}
        />
      )} 
      {
        modals === "selectOrganization" && (
          <ModalSelectOrganization
            history={props.history}
            organizations={props.organizations_select}
            closeModal={() => setModals("")}
          />
        )
      }      
      {showDownloadAllFilesModal && (
        <ModalInput
          title={"Send email"}
          description="Enter your email in order to send you the link to download the files."
          data={{}}
          showModal={true}
          closeModal={() => setShowDownloadAllFilesModal(false)}
          action={(data, id) => exportAllAnswersFiles(data, id)}
          type={"user"}
          edit={true}
          canManage={props.canManage === undefined ? true : props.canManage}
        />

      )}   
      {Object.keys(showModal).length > 0 && (
        <ModalInfoAction
          showModalInfoAction={true}
          closeModalInfoAction={() => {
            setShowModal({});
            setIds([]);
          }}
          noButtonCancel={["error", "success"].includes(showModal.type)}
          text={showModal.text}
          action={() => showModal.action()}
        />
      )}         

      {loading && <Spinner />}
    </motion.section>
  )
};

ProceduresShow.propTypes = {

};

const mapStateToProps = (state, props) => {
  return {
    procedure: state.admin.procedure,
    organization: getOrganization(state, props),
    organizations: getOrganizations(state, props),
    assessments: state.admin.assessments,
    organizations_select: state.admin.organizations_select    
  };
};

const mapDispatchToProps = {
  fetchProcedure,
  fetchOrganizations,
  fetchOrganization,
  fetchOrganizationGetAssessments,
  fetchOrganizationCreate,
  fetchLogout,
  fetchProcedureUpdate,
  fetchProcedureAddUsers,
  fetchProcedureRemoveUsers,
  fetchEmailsProcedureSend,
  fetchKillerExportAll,
  fetchKillerFilesAll,
  fetchSwitchConfigFolder  
};

export default connect(mapStateToProps, mapDispatchToProps)(ProceduresShow);
