/** ORGANIZATIONS **/
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const FETCH_ORGANIZATIONS_OK = "FETCH_ORGANIZATIONS_OK";
export const FETCH_ORGANIZATION = "FETCH_ORGANIZATION";
export const FETCH_ORGANIZATION_OK = "FETCH_ORGANIZATION_OK";
export const FETCH_UPDATE_ORGANIZATION = "FETCH_UPDATE_ORGANIZATION";
export const FETCH_ORGANIZATION_GET_ASSESSMENTS = "FETCH_ORGANIZATION_GET_ASSESSMENTS";
export const FETCH_ORGANIZATION_GET_ASSESSMENTS_OK = "FETCH_ORGANIZATION_GET_ASSESSMENTS_OK";
export const FETCH_ORGANIZATION_CREATE = "FETCH_ORGANIZATION_CREATE";
export const FETCH_ORGANIZATION_CREATE_OK = "FETCH_ORGANIZATION_CREATE_OK";
/**/

/** FOLDERS **/
export const FETCH_FOLDER = "FETCH_FOLDER";
export const FETCH_FOLDER_OK = "FETCH_FOLDER_OK";
export const FETCH_FOLDER_INDEX = "FETCH_FOLDER_INDEX";
export const FETCH_FOLDER_INDEX_OK = "FETCH_FOLDER_INDEX_OK";
export const FETCH_FOLDER_CREATE = "FETCH_FOLDER_CREATE";
export const FETCH_FOLDER_CREATE_OK = "FETCH_FOLDER_CREATE_OK";
export const FETCH_FOLDER_UPDATE = "FETCH_FOLDER_UPDATE";
export const FETCH_FOLDER_UPDATE_OK = "FETCH_FOLDER_UPDATE_OK";
export const FETCH_COPY_CREATE = "FETCH_COPY_CREATE";
export const FETCH_FOLDER_DESTROY = "FETCH_FOLDER_DESTROY";
export const FETCH_FOLDER_DESTROY_OK = "FETCH_FOLDER_DESTROY_OK";
export const FETCH_FOLDER_CREATE_USERS_FOLDER =
  "FETCH_FOLDER_CREATE_USERS_FOLDER";
export const FETCH_FOLDER_CREATE_USERS_FOLDER_OK =
  "FETCH_FOLDER_CREATE_USERS_FOLDER_OK";
export const FETCH_FOLDER_UPDATE_USERS_FOLDER =
  "FETCH_FOLDER_UPDATE_USERS_FOLDER";
export const FETCH_FOLDER_UPDATE_USERS_FOLDER_OK =
  "FETCH_FOLDER_UPDATE_USERS_FOLDER_OK";
export const FETCH_FOLDER_DESTROY_USER = "FETCH_FOLDER_DESTROY_USER";
export const FETCH_FOLDER_DESTROY_USER_OK = "FETCH_FOLDER_DESTROY_USER_OK";
export const FETCH_FOLDER_SEND_INVITATION_USER = "FETCH_FOLDER_SEND_INVITATION_USER";
export const FETCH_FOLDER_SEND_INVITATION_USER_OK = "FETCH_FOLDER_SEND_INVITATION_USER_OK";
export const FETCH_FOLDER_IMPORT_INVITATION_USER = "FETCH_FOLDER_IMPORT_INVITATION_USER";
export const FETCH_FOLDER_START_SURVEY = "FETCH_FOLDER_START_SURVEY";
export const FETCH_FOLDER_UPDATE_CHART_OK = "FETCH_FOLDER_UPDATE_CHART_OK";
export const FETCH_FOLDER_UPDATE_CHART = "FETCH_FOLDER_UPDATE_CHART";
export const FETCH_FOLDER_DOWNLOAD_SCORE_USERS = "FETCH_FOLDER_DOWNLOAD_SCORE_USERS";
export const FETCH_FOLDER_DOWNLOAD_REPORT = "FETCH_FOLDER_DOWNLOAD_REPORT";
export const FETCH_SWITCH_CONFIG_FOLDER = "FETCH_SWITCH_CONFIG_FOLDER"

export const FETCH_KILLER_EXPORT = "FETCH_KILLER_EXPORT";
export const FETCH_KILLER_EXPORT_OK = "FETCH_KILLER_EXPORT_OK";
export const FETCH_KILLER_FILES = "FETCH_KILLER_FILES";
export const FETCH_KILLER_EXPORT_ALL = "FETCH_KILLER_EXPORT_ALL";
export const FETCH_KILLER_FILES_ALL = "FETCH_KILLER_FILES_ALL";

export const FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER = "FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER";
export const FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER_OK = "FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER_OK";
export const FETCH_DELETE_DOCUMENT_PROCEDURE_USER = "FETCH_DELETE_DOCUMENT_PROCEDURE_USER";

export const GET_SCORE_USER_FOLDER = "GET_SCORE_USER_FOLDER"
/**/

/* EMAILS */ 
export const FETCH_EMAILS_FROM_ORGANIZATION = "FETCH_EMAILS_FROM_ORGANIZATION";
export const FETCH_EMAILS_FROM_ORGANIZATION_OK =
  "FETCH_EMAILS_FROM_ORGANIZATION_OK";
export const FETCH_EMAILS_CREATE = "FETCH_EMAILS_CREATE";
export const FETCH_EMAILS_CREATE_OK = "FETCH_EMAILS_CREATE_OK";
export const FETCH_EMAILS_UPDATE = "FETCH_EMAILS_UPDATE";
export const FETCH_EMAILS_UPDATE_OK = "FETCH_EMAILS_UPDATE_OK";
export const FETCH_EMAILS_DESTROY_FROM_ORGANIZATION =
  "FETCH_EMAILS_DESTROY_FROM_ORGANIZATION";
export const FETCH_EMAILS_DESTROY_FROM_ORGANIZATION_OK =
  "FETCH_EMAILS_DESTROY_FROM_ORGANIZATION_OK";
export const FETCH_EMAILS_SHOW = "FETCH_EMAILS_SHOW";
export const FETCH_EMAILS_SHOW_OK = "FETCH_EMAILS_SHOW_OK";
export const FETCH_EMAILS_SEND = "FETCH_EMAILS_SEND";
export const FETCH_EMAILS_SEND_OK = "FETCH_EMAILS_SEND_OK";
export const FETCH_EMAILS_TEST_SEND = "FETCH_EMAILS_TEST_SEND"
/**/

export const FETCH_CONSENT_PROCEDURE_UPDATE = "FETCH_CONSENT_PROCEDURE_UPDATE"
export const FETCH_CONSENT_FOLDER_UPDATE = "FETCH_CONSENT_FOLDER_UPDATE"
/* EMAILS PROCEDURES*/ 
export const FETCH_EMAILS_FROM_PROCEDURE = "FETCH_EMAILS_FROM_PROCEDURE";
export const FETCH_EMAILS_FROM_PROCEDURE_OK =
  "FETCH_EMAILS_FROM_PROCEDURE_OK";
export const FETCH_EMAILS_PROCEDURE_CREATE = "FETCH_EMAILS_PROCEDURE_CREATE";
export const FETCH_EMAILS_PROCEDURE_CREATE_OK = "FETCH_EMAILS_PROCEDURE_CREATE_OK";
export const FETCH_EMAILS_PROCEDURE_UPDATE = "FETCH_EMAILS_PROCEDURE_UPDATE";
export const FETCH_EMAILS_PROCEDURE_UPDATE_OK = "FETCH_EMAILS_PROCEDURE_UPDATE_OK";
export const FETCH_EMAILS_DESTROY_FROM_PROCEDURE =
  "FETCH_EMAILS_DESTROY_FROM_PROCEDURE";
export const FETCH_EMAILS_DESTROY_FROM_PROCEDURE_OK =
  "FETCH_EMAILS_DESTROY_FROM_PROCEDURE_OK";
export const FETCH_EMAILS_PROCEDURE_SHOW = "FETCH_EMAILS_PROCEDURE_SHOW";
export const FETCH_EMAILS_PROCEDURE_SHOW_OK = "FETCH_EMAILS_PROCEDURE_SHOW_OK";
export const FETCH_EMAILS_PROCEDURE_SEND = "FETCH_EMAILS_PROCEDURE_SEND";
export const FETCH_EMAILS_PROCEDURE_SEND_OK = "FETCH_EMAILS_PROCEDURE_SEND_OK";
/**/
/* USERS */
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_OK = "FETCH_USER_OK"
export const FETCH_USER_DELETE_ALL_ANSWERS = "FETCH_USER_DELETE_ALL_ANSWERS";
export const FETCH_USER_DELETE_ALL_ANSWERS_OK = "FETCH_USER_DELETE_ALL_ANSWERS_OK"
export const FETCH_USER_DELETE_MODULE_ANSWER = "FETCH_USER_DELETE_MODULE_ANSWER";
export const FETCH_USER_DELETE_MODULE_ANSWER_OK = "FETCH_USER_DELETE_MODULE_ANSWER_OK"
export const FETCH_USER_UPDATE = "FETCH_USER_UPDATE";
export const FETCH_USER_UPDATE_OK = "FETCH_USER_UPDATE_OK"
/**/

/* ADMINS */
export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_ADMINS_OK = "FETCH_ADMINS_OK"
export const FETCH_SHOW_ADMIN = "FETCH_SHOW_ADMIN";
export const FETCH_SHOW_ADMIN_OK = "FETCH_SHOW_ADMIN_OK"
export const FETCH_ADMIN_UPDATE = "FETCH_ADMIN_UPDATE";
export const FETCH_ADMIN_UPDATE_OK = "FETCH_ADMIN_UPDATE_OK"
export const FETCH_ADMIN_CREATE = "FETCH_ADMIN_CREATE";
export const FETCH_ADMIN_CREATE_OK = "FETCH_ADMIN_CREATE_OK"
export const FETCH_ADMIN_DESTROY = "FETCH_ADMIN_DESTROY";
export const FETCH_ADMIN_DESTROY_OK = "FETCH_ADMIN_DESTROY_OK";
export const FETCH_ADMIN_SEND_EMAIL = "FETCH_ADMIN_SEND_EMAIL";
export const FETCH_ADMIN_SEND_EMAIL_OK = "FETCH_ADMIN_SEND_EMAIL_OK";
/**/

/* LOGS */ 
export const FETCH_LOGS_FROM_ORGANIZATION = "FETCH_LOGS_FROM_ORGANIZATION";
export const FETCH_LOGS_FROM_ORGANIZATION_OK = "FETCH_LOGS_FROM_ORGANIZATION_OK";
/**/

/* PROCEDURES */
export const FETCH_PROCEDURES_INDEX = "FETCH_PROCEDURES_INDEX";
export const FETCH_PROCEDURES_INDEX_OK = "FETCH_PROCEDURES_INDEX_OK";
export const FETCH_PROCEDURE_CREATE = "FETCH_PROCEDURE_CREATE";
export const FETCH_PROCEDURE_UPDATE = "FETCH_PROCEDURE_UPDATE";
export const FETCH_PROCEDURE_ADD_USERS = "FETCH_PROCEDURE_ADD_USERS"
export const FETCH_PROCEDURE_REMOVE_USERS = "FETCH_PROCEDURE_REMOVE_USERS"
export const FETCH_PROCEDURE_CREATE_OK = "FETCH_PROCEDURE_CREATE_OK";
export const FETCH_PROCEDURE = "FETCH_PROCEDURE";
export const FETCH_PROCEDURE_OK = "FETCH_PROCEDURE_OK";
export const FETCH_PROCEDURE_USER = "FETCH_PROCEDURE_USER";
export const FETCH_PROCEDURE_USER_OK = "FETCH_PROCEDURE_USER_OK";
export const FETCH_UPDATE_PROCEDURE_USER = "FETCH_UPDATE_PROCEDURE_USER";
/**/

/* RANKING PROFILE */
export const FETCH_RANKING_PROFILE = "FETCH_RANKING_PROFILE"
export const FETCH_UPDATE_RANKING_PROFILE = "FETCH_UPDATE_RANKING_PROFILE"
export const FETCH_DESTROY_RANKING_PROFILE = "FETCH_DESTROY_RANKING_PROFILE"
export const FETCH_CREATE_RANKING_PROFILE = "FETCH_CREATE_RANKING_PROFILE"
export const FETCH_RANKED_USERS = "FETCH_RANKED_USERS"
export const FETCH_RANKING_PROFILE_ID = "FETCH_RANKING_PROFILE_ID"
/**/

export const FETCH_MOVE_USERS = "FETCH_MOVE_USERS";
export const FETCH_USER_FOLDER_NOTIFICATION = "FETCH_USER_FOLDER_NOTIFICATION"
export const GET_USER_FOLDER_NOTIFICATIONS = "GET_USER_FOLDER_NOTIFICATIONS"
export const DELETE_USER_FOLDER_NOTIFICATIONS = "DELETE_USER_FOLDER_NOTIFICATIONS"
/** Actions to reducers */

/* ORGANIZATIONS */
export function fetchOrganizationsOk(data) {
  return {
    type: FETCH_ORGANIZATIONS_OK,
    data,
  };
}

export function fetchOrganizationOk(data) {
  return {
    type: FETCH_ORGANIZATION_OK,
    data,
  };
}

export function fetchOrganizationGetAssessmentsOk(data) {
  return {
    type: FETCH_ORGANIZATION_GET_ASSESSMENTS_OK,
    data,
  };
}

export function fetchOrganizationCreateOk(data) {
  return {
    type: FETCH_ORGANIZATION_CREATE_OK,
    data,
  };
}

/* */

/* FOLDERS */

export function fetchFolderIndexOk(data) {
  return {
    type: FETCH_FOLDER_INDEX_OK,
    data,
  };
}

export function fetchFolderOk(data) {
  return {
    type: FETCH_FOLDER_OK,
    data,
  };
}

export function fetchFolderCreateOk(data) {
  return {
    type: FETCH_FOLDER_CREATE_OK,
    data,
  };
}

export function fetchFolderUpdateOk(data) {
  return {
    type: FETCH_FOLDER_UPDATE_OK,
    data,
  };
}

export function fetchFolderDestroyOk(data) {
  return {
    type: FETCH_FOLDER_DESTROY_OK,
    data,
  };
}

export function fetchFolderCreateUsersFolderOk(data) {
  return {
    type: FETCH_FOLDER_CREATE_USERS_FOLDER_OK,
    data,
  };
}

export function fetchFolderUpdateUsersFolderOk(data) {
  return {
    type: FETCH_FOLDER_UPDATE_USERS_FOLDER_OK,
    data,
  };
}

export function fetchFolderDestroyUserOk(data) {
  return {
    type: FETCH_FOLDER_DESTROY_USER_OK,
    data,
  };
}

export function fetchFolderSendInvitationUserOk(data) {
  return {
    type: FETCH_FOLDER_SEND_INVITATION_USER_OK,
    data,
  };
}

export function fetchFolderUpdateChartOk(data) {
  return {
    type: FETCH_FOLDER_UPDATE_CHART_OK,
    data,
  };
}
/* */

/* EMAILS */

export function fetchEmailsFromOrganizationOk(data) {
  return {
    type: FETCH_EMAILS_FROM_ORGANIZATION_OK,
    data,
  };
}

export function fetchEmailsCreateOk(data) {
  return {
    type: FETCH_EMAILS_CREATE_OK,
    data,
  };
}

export function fetchEmailsUpdateOk(data) {
  return {
    type: FETCH_EMAILS_UPDATE_OK,
    data,
  };
}

export function fetchEmailsDestroyFromOrganizationOk(data) {
  return {
    type: FETCH_EMAILS_DESTROY_FROM_ORGANIZATION_OK,
    data,
  };
}

export function fetchEmailsShowOk(data) {
  return {
    type: FETCH_EMAILS_SHOW_OK,
    data,
  };
}

export function fetchEmailsSendOk(data) {
  return {
    type: FETCH_EMAILS_SEND_OK,
    data,
  };
}

/* */

/* EMAILS PROCEDURE*/

export function fetchEmailsFromProcedureOk(data) {
  return {
    type: FETCH_EMAILS_FROM_PROCEDURE_OK,
    data,
  };
}

export function fetchEmailsProcedureCreateOk(data) {
  return {
    type: FETCH_EMAILS_PROCEDURE_CREATE_OK,
    data,
  };
}

export function fetchEmailsProcedureUpdateOk(data) {
  return {
    type: FETCH_EMAILS_PROCEDURE_UPDATE_OK,
    data,
  };
}

export function fetchEmailsDestroyFromProcedureOk(data) {
  return {
    type: FETCH_EMAILS_DESTROY_FROM_PROCEDURE_OK,
    data,
  };
}

export function fetchEmailsProcedureShowOk(data) {
  return {
    type: FETCH_EMAILS_PROCEDURE_SHOW_OK,
    data,
  };
}

export function fetchEmailsProcedureSendOk(data) {
  return {
    type: FETCH_EMAILS_PROCEDURE_SEND_OK,
    data,
  };
}

/* */

/* USERS */

export function fetchUserOk(data) {
  return {
    type: FETCH_USER_OK,
    data,
  };
}

export function fetchUserDeleteAllAnswersOk(data) {
  return {
    type: FETCH_USER_DELETE_ALL_ANSWERS_OK,
    data,
  };
}

export function fetchUserDeleteModuleAnswerOk(data) {
  return {
    type: FETCH_USER_DELETE_MODULE_ANSWER_OK,
    data,
  };
}

export function fetchUserUpdateOk(data) {
  return {
    type: FETCH_USER_UPDATE_OK,
    data,
  };
}

/* */

/* ADMINS */

export function fetchAdminsOk(data) {
  return {
    type: FETCH_ADMINS_OK,
    data,
  };
}

export function fetchShowAdminOk(data) {
  return {
    type: FETCH_SHOW_ADMIN_OK,
    data,
  };
}

export function fetchAdminUpdateOk(data) {
  return {
    type: FETCH_ADMIN_UPDATE_OK,
    data,
  };
}

export function fetchAdminCreateOk(data) {
  return {
    type: FETCH_ADMIN_CREATE_OK,
    data,
  };
}

export function fetchAdminDestroyOk(data) {
  return {
    type: FETCH_ADMIN_DESTROY_OK,
    data,
  };
}
/* */

/* PROCEDURES */

export function fetchProceduresIndexOk(data) {
  return {
    type: FETCH_PROCEDURES_INDEX_OK,
    data,
  };
}

export function fetchProcedureCreateOk(data) {
  return {
    type: FETCH_PROCEDURE_CREATE_OK,
    data,
  };
}

export function fetchProcedureOk(data) {
  return {
    type: FETCH_PROCEDURE_OK,
    data,
  };
}

export function fetchProcedureUserOk(data) {
  return {
    type: FETCH_PROCEDURE_USER_OK,
    data,
  };
}

/*  */

/** Actions to sagas */

/* ORGANIZATIONS */
export function fetchOrganizations(success) {
  return {
    type: FETCH_ORGANIZATIONS,
    data: {
      success: success
    }
  };
}

export function fetchOrganization(id, page, number, success, list = false) {
  return {
    type: FETCH_ORGANIZATION,
    data: {
      organizationId: id,
      page: page,
      number: number,
      success: success,
      list: list
    },
  };
}

export function fetchUpdateOrganization(id,data,success) {
  return {
    type: FETCH_UPDATE_ORGANIZATION,
    data: {
      organizationId: id,
      data,
      success: success
    },
  };
}

export function fetchOrganizationGetAssessments(id, success) {
  return {
    type: FETCH_ORGANIZATION_GET_ASSESSMENTS,
    data: {
      organizationId: id,
      success: success
    },
  };
}

export function fetchOrganizationCreate(data,success) {
  return {
    type: FETCH_ORGANIZATION_CREATE,
    data: {
      organizationData: data,
      success: success
    }
  };
}

/* */

/* FOLDERS */
export function fetchFolderIndex(id, page, number, success, update_store = true) {
  
  return {
    type: FETCH_FOLDER_INDEX,
    data: {
      organizationId: id,
      page: page,
      number: number,
      success: success,
      update_store: update_store
    },
  };
}

export function fetchFolder(organizationId, id, page, number, search, status, typeOrder, modeOrder, success) {
  return {
    type: FETCH_FOLDER,
    data: {
      organizationId,
      folderId: id,
      success: success,
      page: page,
      number: number,
      status: status,
      search: search,
      typeOrder: typeOrder, 
      modeOrder: modeOrder
    },
  };
}

export function fetchFolderCreate(organizationId, data, success) {
  return {
    type: FETCH_FOLDER_CREATE,
    data: {
      organizationId,
      folder: data,
      success,
    },
  };
}

export function fetchCopyFolder(organizationId, folderId, data, success) {
  return {
    type: FETCH_COPY_CREATE,
    data: {
      organizationId,
      folderId,
      folder: data,
      success,
    },
  };
}


export function fetchSwitchConfigFolder(organizationId, folderId, data, success) {
  return {
    type: FETCH_SWITCH_CONFIG_FOLDER,
    data: {
      organizationId,
      folderId,
      folder: data,
      success,
    },
  };
}

export function fetchFolderUpdate(organizationId, folderId, data, success) {
  return {
    type: FETCH_FOLDER_UPDATE,
    data: {
      organizationId,
      folderId,
      folder: data,
      success,
    },
  };
}

export function fetchFolderDestroy(organizationId, folderId, success) {
  return {
    type: FETCH_FOLDER_DESTROY,
    data: {
      organizationId,
      folderId,
      success,
    },
  };
}

export function fetchFolderCreateUsersFolder(
  organizationId,
  folderId,
  data,
  success
) {
  return {
    type: FETCH_FOLDER_CREATE_USERS_FOLDER,
    data: {
      organizationId,
      folderId,
      data,
      success,
    },
  };
}

export function fetchFolderUpdateUsersFolder(
  organizationId,
  folderId,
  userFolderId,
  data,
  success
) {
  return {
    type: FETCH_FOLDER_UPDATE_USERS_FOLDER,
    data: {
      organizationId,
      folderId,
      userFolderId,
      user_folder: data,
      success,
    },
  };
}

export function fetchFolderDestroyUser(organizationId, folderId, ids, success) {
  return {
    type: FETCH_FOLDER_DESTROY_USER,
    data: {
      organizationId,
      folderId,
      ids,
      success,
    },
  };
}

export function fetchFolderSendInvitationUser(
  organizationId,
  folderId,
  ids,
  success
) {
  return {
    type: FETCH_FOLDER_SEND_INVITATION_USER,
    data: {
      organizationId,
      folderId,
      ids,
      success,
    },
  };
}

export function startSurveyFolder(organizationId, id, success, error) {
  return {
    type: FETCH_FOLDER_START_SURVEY,
    data: {
      organizationId,
      folderId: id,
      success,
      error
    }
  }
}

export function fetchFolderUpdateChart(organizationId, folderId,userStatus, success, error) {
  return {
    type: FETCH_FOLDER_UPDATE_CHART,
    data: {
      organizationId,
      folderId,
      userStatus,
      success,
      error
    }
  }
}

export function fetchFolderDownloadScoreUsers(organizationId, folderId,email,typeCsv, success, error) {
  return {
    type: FETCH_FOLDER_DOWNLOAD_SCORE_USERS,
    data: {
      organizationId,
      folderId,
      email,
      typeCsv,
      success,
      error
    }
  }
}

export function fetchFolderDownloadReports(organizationId, folderId, email, success, error) {
  return {
    type: FETCH_FOLDER_DOWNLOAD_REPORT,
    data: {
      organizationId,
      folderId,
      email,
      success,
      error
    }
  }
}

/* */

/* EMAILS */

export function fetchEmailsFromOrganization(
  organizationId,
  folderId,
  page,
  number,
  success
) {
  return {
    type: FETCH_EMAILS_FROM_ORGANIZATION,
    data: {
      organizationId: organizationId,
      folderId: folderId ? folderId : "",
      page: page,
      number: number,
      success,
    },
  };
}

export function fetchEmailsCreate(organizationId, folderId, email, success) {
  return {
    type: FETCH_EMAILS_CREATE,
    data: {
      organizationId,
      folderId,
      email,
      success,
    },
  };
}

export function fetchEmailsUpdate(organizationId, folderId,idEmail, email, success) {
  return {
    type: FETCH_EMAILS_UPDATE,
    data: {
      organizationId,
      folderId,
      idEmail,
      email,
      success,
    },
  };
}

export function fetchEmailsDestroyFromOrganization(
  organizationId,
  folderId,
  emailId,
  success
) {
  return {
    type: FETCH_EMAILS_DESTROY_FROM_ORGANIZATION,
    data: {
      organizationId,
      folderId,
      emailId,
      success,
    },
  };
}

export function fetchEmailsShow(organizationId, folderId, emailId, success) {
  return {
    type: FETCH_EMAILS_SHOW,
    data: {
      organizationId,
      folderId,
      emailId,
      success,
    },
  };
}

export function fetchEmailsSend(
  organizationId,
  folderId,
  emailId,
  ids,
  success,
  error
) {
  return {
    type: FETCH_EMAILS_SEND,
    data: {
      organizationId,
      folderId,
      emailId,
      ids,
      success,
      error
    },
  };
}

export function fetchEmailsTestSend(
  organizationId,
  emailId,
  email,
  success,
  error
) {
  return {
    type: FETCH_EMAILS_TEST_SEND,
    data: {
      organizationId,
      emailId,
      email,
      success,
      error
    },
  };
}
/* EMAILS PROCEDURES*/

export function fetchEmailsFromProcedure(
  organizationId,
  procedureId,
  page,
  number,
  success
) {
  return {
    type: FETCH_EMAILS_FROM_PROCEDURE,
    data: {
      organizationId: organizationId,
      procedureId: procedureId,
      page: page,
      number: number,
      success,
    },
  };
}

export function fetchEmailsProcedureCreate(organizationId, procedureId, email, success) {
  return {
    type: FETCH_EMAILS_PROCEDURE_CREATE,
    data: {
      organizationId,
      procedureId,
      email,
      success,
    },
  };
}

export function fetchEmailsProcedureUpdate(organizationId, procedureId,idEmail, email, success) {
  return {
    type: FETCH_EMAILS_PROCEDURE_UPDATE,
    data: {
      organizationId,
      procedureId,
      idEmail,
      email,
      success,
    },
  };
}

export function fetchConsentProcedureUpdate(organizationId, procedureId, consent, success) {
  return {
    type: FETCH_CONSENT_PROCEDURE_UPDATE,
    data: {
      organizationId,
      procedureId,
      consent,
      success,
    },
  };
}

export function fetchConsentFolderUpdate(organizationId, folderId, consent, success) {
  return {
    type: FETCH_CONSENT_FOLDER_UPDATE,
    data: {
      organizationId,
      folderId,
      consent,
      success,
    },
  };
}


export function fetchEmailsDestroyFromProcedure(
  organizationId,
  procedureId,
  emailId,
  success
) {
  return {
    type: FETCH_EMAILS_DESTROY_FROM_PROCEDURE,
    data: {
      organizationId,
      procedureId,
      emailId,
      success,
    },
  };
}

export function fetchEmailsProcedureShow(organizationId, procedureId, emailId, success) {
  return {
    type: FETCH_EMAILS_PROCEDURE_SHOW,
    data: {
      organizationId,
      procedureId,
      emailId,
      success,
    },
  };
}

export function fetchEmailsProcedureSend(
  organizationId,
  procedureId,
  emailId,
  ids,
  success,
  error
) {
  return {
    type: FETCH_EMAILS_PROCEDURE_SEND,
    data: {
      organizationId,
      procedureId,
      emailId,
      ids,
      success,
      error
    },
  };
}

/*  */
/*  */

/* USERS */

export function fetchUser(organizationId, folderId, userId, success) {
  return {
    type: FETCH_USER,
    data: {
      organizationId,
      folderId,
      userId,
      success,
    },
  };
}

export function fetchUserDeleteAllAnswers(url, success) {

  return {
    type: FETCH_USER_DELETE_ALL_ANSWERS,
    data: {
      url,
      success,
    },
  };
}

export function fetchUserDeleteModuleAnswer(url, success) {

  return {
    type: FETCH_USER_DELETE_MODULE_ANSWER,
    data: {
      url,
      success,
    },
  };
}

export function fetchUserUpdate(organizationId, folderId, userId,profile, success) {

  return {
    type: FETCH_USER_UPDATE,
    data: {
      organizationId,
      folderId,
      userId,
      profile,
      success,
    },
  };
}
/*  */

/* ADMINS */

export function fetchAdmins(organizationId, success) {
  return {
    type: FETCH_ADMINS,
    data: {
      organizationId,
      success: success
    },
  };
}

export function fetchShowAdmin(organizationId, adminId, success) {
  return {
    type: FETCH_SHOW_ADMIN,
    data: {
      organizationId,
      adminId,
      success,
    },
  };
}

export function fetchAdminUpdate(organizationId, adminId,profile, success) {

  return {
    type: FETCH_ADMIN_UPDATE,
    data: {
      organizationId,
      adminId,
      profile,
      success,
    },
  };
}

export function fetchAdminCreate(organizationId,profile, success) {

  return {
    type: FETCH_ADMIN_CREATE,
    data: {
      organizationId,
      profile,
      success,
    },
  };
}

export function fetchAdminDestroy(organizationId, ids, success) {
  return {
    type: FETCH_ADMIN_DESTROY,
    data: {
      organizationId,
      ids,
      success,
    },
  };
}

export function fetchAdminSendEmail(
  organizationId,
  ids,
  success,
  error
) {
  return {
    type: FETCH_ADMIN_SEND_EMAIL,
    data: {
      organizationId,
      ids,
      success,
      error
    },
  };
}

/*  */

/* ADMINS */

export function fetchLogsFromOrganization(
  organizationId,
  page,
  number,
  success
) {
  return {
    type: FETCH_LOGS_FROM_ORGANIZATION,
    data: {
      organizationId: organizationId,
      page: page,
      number: number,
      success,
    },
  };
}

export function fetchLogsFromOrganizationOk(data) {
  return {
    type: FETCH_LOGS_FROM_ORGANIZATION_OK,
    data,
  };
}

/*  */

/* PROCEDURES */

export function fetchProceduresIndex(id, page, number, success) {
  return {
    type: FETCH_PROCEDURES_INDEX,
    data: {
      organizationId: id,
      page: page,
      number: number,
      success: success
    },
  };
}

export function fetchProcedureCreate(organizationId, data, success, error) {
  return {
    type: FETCH_PROCEDURE_CREATE,
    data: {
      organizationId,
      procedure: {procedure: data},
      success,
      error
    },
  };
}

export function fetchProcedureAddUsers(organizationId, id, data, success, error) {
  return {
    type: FETCH_PROCEDURE_ADD_USERS,
    data: {
      organization_id: organizationId,
      id: id, 
      data,
      success,
      error
    },
  };
}

export function fetchProcedureRemoveUsers(organizationId, id, data, success, error) {
  return {
    type: FETCH_PROCEDURE_REMOVE_USERS,
    data: {
      organization_id: organizationId,
      id: id, 
      data,
      success,
      error
    },
  };
}

export function fetchProcedure(organizationId, id, page, number, search, status, typeOrder, modeOrder, invited_by, success) {
  return {
    type: FETCH_PROCEDURE,
    data: {
      organizationId,
      procedureId: id,
      page: page,
      number: number,
      status: status,
      search: search,      
      typeOrder: typeOrder, 
      modeOrder: modeOrder,
      invited_by: invited_by,
      success: success
    },
  };
}

export function fetchProcedureUpdate(organizationId, id, data, success, error) {
  return {
    type: FETCH_PROCEDURE_UPDATE,
    data: {
      organizationId,
      id: id,
      procedure: data,
      success: success,
      error: error
    },
  };
}


export function fetchProcedureUser(organizationId, procedureId, userId, success) {
  return {
    type: FETCH_PROCEDURE_USER,
    data: {
      organizationId: organizationId,
      procedureId: procedureId,
      userId: userId,
      success: success
    },
  };
}

export function fetchKillerExport(organizationId, procedureId,procedureUserId, success, error) {
  return {
    type: FETCH_KILLER_EXPORT,
    data: {
      organizationId,
      procedureId,
      procedureUserId,
      success,
      error
    }
  }
}

export function uploadProcedureUserDocument(data, organizationId, procedureId,procedureUserId, success, error) {
  return {
    type: FETCH_UPLOAD_DOCUMENT_PROCEDURE_USER,
    data: {
      data,
      organizationId,
      procedureId,
      procedureUserId,
      success,
      error
    }
  }
}

export function deleteDocumentProcedureUser(documentId, organizationId, procedureId,procedureUserId, success, error) {
  return {
    type: FETCH_DELETE_DOCUMENT_PROCEDURE_USER,
    data: {
      documentId,
      organizationId,
      procedureId,
      procedureUserId,
      success,
      error
    }
  }
}


export function fetchKillerExportAll(organizationId, procedureId, success, error) {
  return {
    type: FETCH_KILLER_EXPORT_ALL,
    data: {
      organizationId,
      procedureId,
      success,
      error
    }
  }
}

export function getScoreUserFolder(organizationId, procedureId,procedureUserId, user_folder_id, success, error) {
  return {
    type: GET_SCORE_USER_FOLDER,
    data: {
      organizationId,
      procedureId,
      procedureUserId,
      user_folder_id,
      success,
      error
    }
  }
}

export function fetchKillerFiles(organizationId, procedureId,procedureUserId, email, success, error) {
  return {
    type: FETCH_KILLER_FILES,
    data: {
      organizationId,
      procedureId,
      procedureUserId,
      email,
      success,
      error
    }
  }
}

export function fetchKillerFilesAll(organizationId, procedureId, email, success, error) {
  return {
    type: FETCH_KILLER_FILES_ALL,
    data: {
      organizationId,
      procedureId,
      email,
      success,
      error
    }
  }
}

export function fetchUpateProcedureUser(organizationId, procedureId, procedureUserId, data, success, error) {
  return {
    type: FETCH_UPDATE_PROCEDURE_USER,
    data: {
      organizationId,
      procedureId,
      procedureUserId,
      data,
      success,
      error
    }
  }
}

export function fetchRankingProfileFolder(organizationId, folderId, success, error) {
  return {
    type: FETCH_RANKING_PROFILE,
    data: {
      organizationId,
      folderId,
      success,
      error
    },
  };
}

export function fetchRankingProfileFolderId(organizationId, folderId, id, success, error) {
  return {
    type: FETCH_RANKING_PROFILE_ID,
    data: {
      organizationId,
      folderId,
      id,
      success,
      error
    },
  };
}

export function fetchUpdateRankingProfileFolder(organizationId, folderId, id, data, success, error) {
  return {
    type: FETCH_UPDATE_RANKING_PROFILE,
    data: {
      organizationId,
      folderId,
      id,
      data,
      success,
      error
    },
  };
}

export function fetchDestroyRankingProfileFolder(organizationId, folderId, id, success, error) {
  return {
    type: FETCH_DESTROY_RANKING_PROFILE,
    data: {
      organizationId,
      folderId,
      id,
      success,
      error
    },
  };
}

export function fetchCreateRankingProfileFolder(organizationId, folderId, data, success, error) {
  return {
    type: FETCH_CREATE_RANKING_PROFILE,
    data: {
      organizationId,
      folderId,
      data,
      success,
      error
    },
  };
}

export function fetchRankedUsers(organizationId, folderId, id, success, error) {
  return {
    type: FETCH_RANKED_USERS,
    data: {
      organizationId,
      folderId,
      id,
      success,
      error
    },
  };
}

export function moveUsers(organizationId, data, success, error) {
  return {
    type: FETCH_MOVE_USERS,
    data: {
      organizationId,
      data,
      success,
      error
    },
  };
}

export function createUserFolderNotification(organizationId, data, success, error) {
  return {
    type: FETCH_USER_FOLDER_NOTIFICATION,
    data: {
      organizationId,
      data,
      success,
      error
    },
  };
}

export function getUserFolderNotifications(organizationId, user_folder_id, success, error) {
  return {
    type: GET_USER_FOLDER_NOTIFICATIONS,
    data: {
      organizationId,
      user_folder_id,
      success,
      error
    },
  };
}

export function deleteUserFolderNotification(organizationId, id, success, error) {
  return {
    type: DELETE_USER_FOLDER_NOTIFICATIONS,
    data: {
      organizationId,
      id,
      success,
      error
    },
  };
}


/*  */


