export const routes = {
  session: {
    create: "users/sign_in",
    destroy: "users/sign_out",
  },
  passwords: {
    create: "users/password",
    update: "users/password",
  },
  users: {
    indexUser: "users",
    showUser: (id) => `users/${id}`,
    showProfile: (id) => `users/${id}/profile`,
    updateProfile: (id) => `users/${id}/profile`,
  },
  surveys: {
    indexSurveys: "evaluations",
    showSurvey: (token) => `evaluations/${token}`,
    showModule: (token, module_id) =>
      `evaluations/${token}?module_id=${module_id}`,
    setUserFolderLanugage: (user_folder_id) => 
      `user_folders/${user_folder_id}`,
  },
  scores: {
    createScore: (token) => `answers?token=${token}`,
    resetScore: (organizationId, folderId, id) =>
      `organizations/${organizationId}/folders/${folderId}/scores/${id}`,
  },
  folderInvitations: {
    showInvitation: (token) => `invitations/${token}`,
    createInvitation: (token) => `invitations/${token}`,
  },
  organizations: {
    indexOrganizations: "admin/organizations",
    showOrganization: (id, page, number, list) =>
      `admin/organizations/${id}?page=${page}&number=${number}&list=${list}&`,
    updateOrganization: (id) =>
      `admin/organizations/${id}`,  
    assessmentsOrganization: (id) =>
      `admin/organizations/${id}/new_organization`,
  },
  folders: {
    indexFolder: (organizationId, page, number) =>
      `admin/organizations/${organizationId}/folders/?page=1&number=5000`,
    showFolder: (organizationId, id, page, number, status, search, modeOrder, typeOrder) =>
      `admin/organizations/${organizationId}/folders/${id}?page=${page}&number=${number}&search=${search}&status=${status}&modeOrder=${modeOrder}&typeOrder=${typeOrder}`,
    createFolder: (organizationId) =>
      `admin/organizations/${organizationId}/folders/`,
    copyFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/copy_folder`,
    switchConfigFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/switch_configuration`,
    updateFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/`,
    createUsersFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/user_folders`,
    updateUsersFolder: (organizationId, id, userFolderId) =>
      `admin/organizations/${organizationId}/folders/${id}/user_folders/${userFolderId}`,
    destroyUsersFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/user_folders/destroy_users`,
    sendInvitationUsersFolder: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/user_folders/send_invitation`,
    startSurvey: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/start_survey`,
    showReport: (organizationId,folderId,userFolderId,reportID) =>
       `admin/organizations/${organizationId}/folders/${folderId}/user_folders/${userFolderId}/reports/${reportID}`,
    updateChart: (organizationId, id, userStatus) =>
      `admin/organizations/${organizationId}/folders/${id}/filtered_graphic?status_users=${userStatus}`,
    downloadScoreUsers: (organizationId, id,email,typeCsv) =>
      `admin/organizations/${organizationId}/folders/${id}/download_score_users?email=${email}&type_csv=${typeCsv}`,
    downloadReports: (organizationId, id, email) =>
      `admin/organizations/${organizationId}/folders/${id}/download_all_reports?email=${email}`,
    indexRankingProfile: (organizationId, folderId, page, number) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles?page=1&number=5000`,
    showRankingProfileId:  (organizationId, folderId, id, page, number) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles/${id}`,
    showRankingProfile: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles/${id}`,
    createRankingProfile: (organizationId, folderId) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles`,
    destroyRankingProfile: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles/${id}`,
    destroyRankingProfile: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranking_profiles/${id}`,
    getRankedUsers: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/folders/${folderId}/ranked_users?id=${id}`,   
    fetchMoveUsersSaga: (organizationId) =>
      `admin/organizations/${organizationId}/folders/move_users`,
    fetchUserFolderNotification: (organizationId) =>
      `admin/organizations/${organizationId}/user_folder_notifications`,  
    getUserFolderNotifications: (organizationId, user_folder_id) =>
      `admin/organizations/${organizationId}/user_folder_notifications?user_folder_id=${user_folder_id}`,  
    destroyUserFolderNotification: (organizationId, id) =>
      `admin/organizations/${organizationId}/user_folder_notifications/${id}`,        
      
  },

  emails: {
    indexEmailsFromOrganization: (organizationId, folderId, page, number) =>
      `admin/organizations/${organizationId}/custom_mails/?folder_id=${folderId}&page=${page}&number=${number}`,
    createEmails: (organizationId, folderId) =>
      `admin/organizations/${organizationId}/custom_mails/?folder_id=${folderId}`,
    updateEmails: (organizationId, folderId, idEmail) =>
      `admin/organizations/${organizationId}/custom_mails/${idEmail}?folder_id=${folderId}`,
    destroyEmailsFromOrganization: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/custom_mails/${id}?folder_id=${folderId}`,
    showEmails: (organizationId, folderId, id) =>
      `admin/organizations/${organizationId}/custom_mails/${id}?folder_id=${folderId}`,
    sendEmails: (organizationId,folderId, id) =>
      `admin/organizations/${organizationId}/custom_mails/${id}/send_email/?folder_id=${folderId}`,
    sendEmailsTest: (organizationId, id) =>
      `admin/organizations/${organizationId}/custom_mails/${id}/send_test_email`,  
  },
  consent: {
    updateConsent: (organizationId, id) =>
      `admin/organizations/${organizationId}/procedures/${id}/update_consent`, 
    updateFolderConsent: (organizationId, id) =>
      `admin/organizations/${organizationId}/folders/${id}/update_consent`,        
  },

  adminUser: {
    user: (organizationId, id, folderId) =>
      `admin/organizations/${organizationId}/users/${id}?user_folder_id=${folderId}`,
    admins: (organizationId) =>
      `admin/organizations/${organizationId}/administrators`,
    adminShow: (organizationId, id) =>
      `admin/organizations/${organizationId}/administrators/${id}`,
    adminUpdate: (organizationId, id) =>
      `admin/organizations/${organizationId}/administrators/${id}`,
    adminCreate: (organizationId) =>
      `admin/organizations/${organizationId}/administrators`,
    adminDestroy: (organizationId) =>
      `admin/organizations/${organizationId}/administrators/destroy_admins`,
    adminSendEmail: (organizationId) =>
      `admin/organizations/${organizationId}/administrators/resend_email`,
  },

  logs: {
    indexLogsFromOrganization: (organizationId, page, number) =>
      `admin/organizations/${organizationId}/logs/?page=${page}&number=${number}`,
  },

  procedures: {
    proceduresIndex: (organizationId, page, number) =>
      `admin/organizations/${organizationId}/procedures/?page=${page}&number=${number}`,
    proceduresShow: (organizationId, id, page, number, status, search, modeOrder, typeOrder, invited_by) =>
    `admin/organizations/${organizationId}/procedures/${id}?page=${page}&number=${number}&search=${search}&status=${status}&modeOrder=${modeOrder}&typeOrder=${typeOrder}&invited_by=${invited_by}`,
    killerExportShow: (organizationId, procedureId, procedureUserId) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}/killer_export`,
    killerExportAll: (organizationId, procedureId) => `admin/organizations/${organizationId}/procedures/${procedureId}/killer_export_all`,
    killerFilesAll: (organizationId, procedureId, email) => `admin/organizations/${organizationId}/procedures/${procedureId}/killer_files_all?email=${email}`,
    killerFilesShow: (organizationId, procedureId, procedureUserId, email) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}/killer_files?email=${email}`,
    getScoreUserFolder: (organizationId, procedureId, procedureUserId, user_folder_id) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}/get_score?user_folder_id=${user_folder_id}`,
    uploadDocumentProcedureUser: (organizationId, procedureId, procedureUserId) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}/upload_document`,    
    deleteDocumentProcedureUser: (organizationId, procedureId, procedureUserId) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}/delete_document`,    
    proceduresUserShow: (organizationId, procedureId, userId) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${userId}`,
    proceduresUpdateUserShow: (organizationId, procedureId, procedureUserId) => `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_users/${procedureUserId}`,
    createProcedure: (organizationId) => `admin/organizations/${organizationId}/procedures/`,
    updateProcedure: (organizationId, procedureId) =>  `admin/organizations/${organizationId}/procedures/${procedureId}`,
    addProcedureUsers: (organization_id, procedureId) =>  `admin/organizations/${organization_id}/procedures/${procedureId}/add_users`,
    removeProcedureUsers: (organization_id, procedureId) =>  `admin/organizations/${organization_id}/procedures/${procedureId}/remove_users`,
    setAnswerScore: (organizationId, folderID, userFolderId) => `admin/organizations/${organizationId}/folders/${folderID}/user_folders/${userFolderId}/evaluate_answer`,
  },
  procedures_email: {
    indexEmailsFromProcedure: (organizationId, procedureId, page, number) =>
      `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails`,
    createEmails: (organizationId, procedureId) =>
       `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails`,
    updateEmails: (organizationId, procedureId, emailId) =>
      `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails/${emailId}`,
    destroyEmailsFromProcedure: (organizationId, procedureId, emailId) =>
      `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails/${emailId}`,
    showEmails: (organizationId, procedureId, emailId) =>
      `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails/${emailId}`,
    sendEmails: (organizationId,procedureId, emailId) =>
      `admin/organizations/${organizationId}/procedures/${procedureId}/procedure_mails/${emailId}/send_email`,
  },  

  client: {
    signin: "/signin",
    profileSignin: "/signin/:user_folder_token",
    beforeContinuing: "/before_continuing",
    disclaimer: "/disclaimer",
    policy: "/policy",
    policy_uax: "/policy_uax",
    checkproctoring: "/checkproctoring",
    rescuePassword: "/rescue_password",
    changePassword: "/change_password",
    home: "/",
    myProfile: "/profile",
    surveys: "/surveys",
    survey: "/survey/:surveyToken",
    survey_module: "/survey/:surveyToken/:module_id",
    surveyRedirect: (token) => `/survey/${token}`,
    procedure: "/procedure/:slug",
    createProcedure: "procedures",
    getProcedureClient: (slug) => `procedures/${slug}`,
    procedure_not_passed: "/procedure_end"
  },
  admin: {
    signin: "/admin/signin",
    home: "/admin",
    organizations: "/admin/organizations",
    organization: "/admin/organizations/:organizationId",
    organizationRedirect: (id) => `/admin/organizations/${id}`,
    folderIndex: "/admin/organizations/:organizationId/surveys",
    folder: "/admin/organizations/:organizationId/surveys/:folderId",
    folderRedirect: (organizationId, id) =>
      `/admin/organizations/${organizationId}/folders/${id}`,
    user: "/users/:id",
    userRedirect: (id) => `/users/${id}`,
    emails: "/admin/organizations/:organizationId/surveys/:folderId/emails",
    emailsRedirect: (organizationId) => `/admin/emails/${organizationId}`,
    emailsCreate: "/admin/organizations/:organizationId/emails/new",
    emailsShow: "/admin/organizations/:organizationId/emails/show/:id",
    administrators: "/admin/organizations/:organizationId/administrators",
    logs: "/admin/organizations/:organizationId/logs",
    procedures: "/admin/organizations/:organizationId/procedures",
    procedureNew: "/admin/organizations/:organizationId/procedure/new",
    proceduresShow: "/admin/organizations/:organizationId/procedures/:id",
    proceduresUserShow: "/admin/organizations/:organizationId/procedures/:procedureId/procedure_users/:userId",
    proceduresEmails: "/admin/organizations/:organizationId/procedures/:id/emails",
    procedureConsentEdit:"/admin/organizations/:organizationId/procedures/:id/consent",
    surveyConsentEdit:"/admin/organizations/:organizationId/surveys/:id/consent",
    organizationIndexEmails: "/admin/organizations/:organizationId/custom_mails",
    rankingProfile: "/admin/organizations/:organizationId/surveys/:folderId/ranking_profiles/:id",    

    
  },
};

export default routes;
